const BASE = {
	BASE_URL: '/',
	IS_PROMOTION: true,
	SHOPIFY_COOKIE_EXPIRE: 90,
	SHOPIFY_API_VERSION: '2023-10',
	APP_SHORT_NAME: 'RMK_GIFTCARD',
	APP_NAME: "Rumi's Kitchen Gift Card",

	//* Parse
	Parse: {
		appId: '<appID>',
		//exp: 'myAppId',

		serverURL: '<serverURL>',
		//exp: 'https://back.test.com/parse'

		liveQueryServerURL: '<liveQueryServerURL>',
		//exp: 'wss://back.test.com'
	},
};

const PRODUCTION = {
	SERVER: 'https://back.giftcard.rumishq.com',
	SHOPIFY_DOMAIN: 'rumis-kitchen-gift-card-store.myshopify.com',
	SHOPIFY_STOREFRONT_API_ACCESS_TOKEN: '45246a7e02372be3960b0fbe0e069a0e',
};

const DEVELOPMENT = {
	SERVER: 'https://back.dev.giftcard.rumishq.com',
	SHOPIFY_DOMAIN: 'rumis-gift-dev.myshopify.com',
	SHOPIFY_STOREFRONT_API_ACCESS_TOKEN: 'f30c4b22b7f8b45030e52fec5e3e96fa',
};

const LOCAL = {
	SERVER: 'https://back.dev.giftcard.rumishq.com',
	SHOPIFY_DOMAIN: 'rumis-gift-dev.myshopify.com',
	SHOPIFY_STOREFRONT_API_ACCESS_TOKEN: 'f30c4b22b7f8b45030e52fec5e3e96fa',
};

//? --------------------- Config selection ----------------------------------------

export type CONFIG_TYPE = typeof BASE & typeof PRODUCTION & typeof DEVELOPMENT & typeof LOCAL;

const CONFIG_BY_MODE: CONFIG_TYPE = {
	...(({ LOCAL, PRODUCTION, DEVELOPMENT } as any)[window.MODE as any] || {}),
};

export const CONFIG = {
	...BASE,
	...CONFIG_BY_MODE,
	API_SERVER: CONFIG_BY_MODE?.SERVER + '/api',
};

window.CONFIG = CONFIG;
